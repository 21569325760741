import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeaderSection from '../components/sections/HeaderSection';

import RocketIcon from '../images/icons/rocket.svg';
import LightBulbIcon from '../images/icons/light-bulb.svg';
import TimeLine from '../components/sections/TimeLine';
import AreYouInterested from '../components/sections/AreYouInterested';
import ToolOverview from '../components/pages/career/ToolOverview';
import WohinDuWillstProjectBox from '../components/sections/project-boxes/WohinDuWillst';
import CityLinkProjectBox from '../components/sections/project-boxes/CityLink';

const ServicePage = () => (
  <Layout>
    <SEO title="Leistungen" keywords={[]} />
    <HeaderSection>
      <h1>
        hochzehn.
        <br />
        herausfordernd.
        <br />
        innovativ.
      </h1>
      <div className="flex flex-wrap">
      <p className="flex w-full md:w-1/2 mt-8 pl-0">
        Bei der Entwicklung steht der Nutzer im Mittelpunkt - Denn nur wenn dieser einen echten Mehrwert
        durch das Produkt erfährt, kann darauf auch das Geschäftsmodell unserer Kunden aufgebaut werden.
        <br></br><br></br>
        Um die Produkte nicht am Markt vorbei zu entwicklen, begeben wir uns sehr schnell in die Feedback-Schleife:
        Entwickeln - Testen - Auswerten - Optimieren.
      </p>
      <p className="flex w-full md:w-1/2 mt-8 pl-0 md:pl-3">
        Das erste Produkt welches dem Nutzer präsentieren wird, das Minimum Viable Product (MVP), enthält nur die
        Funktionen die notwendig sind, um ein verlässliches Nutzerfeedback zu erhalten. Mit den folgenden
        Iterationen werden dem Produkt schrittweise weitere Funktionen hinzugefügt und getestet.

        Die agile Methodik ermöglicht dabei, die Priorisierung zukünftiger Entwicklungsschritte laufend zu aktualisieren
        und diese an neue Markterkenntnisse oder andere äußere Einflüsse anzupassen.

      </p>
      </div>
    </HeaderSection>

    <div className="flex flex-row justify-center">
      <div className="flex flex-col w-full items-center">
        <h3>Welche Leistungen interessieren dich?</h3>
        <div className="flex flex-row w-full justify-center flex-wrap mt-4">
          <div className="flex flex-col w-full sm:w-auto">
            <div className="flex flex-row">
              <div className="w-1/2 md:w-auto mr-2 flex-grow">
                <AnchorLink
                  className="h-full button button-animate button-outlined flex flex-col sm:flex-row items-center justify-between "
                  href="#digitale-loesungen"
                >
                  <span className="sm:w-1/2 md:w-auto">Agile Entwicklung</span>

                  <FontAwesomeIcon className="mr-4 mt-4 sm:mr-0 sm:mt-0" icon={faArrowDown} />

                </AnchorLink>
              </div>
              <div className="w-1/2 md:w-auto ml-2 flex-grow">
                <AnchorLink
                  className="h-full button button-animate button-outlined flex flex-col sm:flex-row items-center justify-between "
                  href="#mvp-projects"
                >
                  <span>MVP zum Fixpreis</span>

                  <FontAwesomeIcon className="mr-4 mt-4 sm:mr-0 sm:mt-0" icon={faArrowDown} />

                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section id="digitale-loesungen">
      <div className="flex flex-row flex-wrap -mx-4 sm:mx-0 p-4 md:p-10 bg-grey-lighter">
        <div className="flex flex-col w-full">
          <h2 className="text-brand">Agile Entwicklung</h2>
        </div>
        <div className="flex flex-col w-full mt-8">
          <h3 className="font-bold w-full md:w-2/3">Wir schaffen individuelle und passgenaue digitale Lösungen</h3>
          <p className="mt-3">
            Gemeinsam mit dir entwickeln wir Lösungen interaktiv und individuell. Perfekt ausformulierte Lastenhefte brauchen wir nicht -
            Im Gegenteil: Wir denken und gestalten gerne mit und sind überzeugt, dass das perfekte Produkt nur mit einem aktiven Ohr
            für Bedürfnissen der Nutzer entsteht. Eine agile Projektdurchführung ermöglicht es uns, flexibel auf veränderte Anforderungen zu reagieren.
          </p>
        </div>

        <div className="flex flex-col w-full mt-8">
          <div className="flex flex-row flex-wrap">
            <div className="flex flex-col my-2 mr-16">
              <img src={LightBulbIcon} className="w-8 h-8" alt="Icon" />
              <span className="font-bold mt-3">
                Konzeption
              </span>
              <ul className="list-reset mt-3 leading-normal">
                <li>Strategie</li>
                <li>Beratung</li>
                <li>Produktkonzept</li>
                <li>Planung</li>
              </ul>
            </div>
            <div className="flex flex-col my-2 mr-16">
              <img src={RocketIcon} className="w-8 h-8" alt="Icon" />
              <span className="font-bold mt-3">
                Entwicklung
              </span>
              <ul className="list-reset mt-3 leading-normal">
                <li>Websites</li>
                <li>iOS App</li>
                <li>Android App</li>
                <li>Backoffice Systeme</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap w-full mt-10">
          <div className="flex flex-col w-full">
            <span className="font-bold">Beispielprojekt für agile Entwicklung</span>
            <WohinDuWillstProjectBox />
          </div>
        </div>
      </div>
    </section>

    <section id="mvp-projects">
      <div className="flex flex-row flex-wrap -mx-4 p-4 sm:mx-0">
        <div className="flex flex-col w-full">
          <h2 className="text-brand">Minimum Viable Product (MVP) zum Fixpreis</h2>
        </div>
        <div className="flex flex-col w-full mt-8">
          <div className="flex flex-row flex-wrap">
            <div className="flex flex-col md:w-1/2 md:pr-4">
              <h3 className="font-bold w-full md:w-2/3">Projektvorgehen</h3>
              <p className="mt-3">
                Jedes Projekt ist individuell auf dich zugeschnnitten. Nach einem ersten gemeinsamen
                Produktplanungsworkshop entwickeln wir aus deiner Idee ein Produktvision. Anschließend brechen wir
                diese Vision herunter auf die wesentlichen Kernfeatures, die für das MVP zwingend erforderlich sind.
                Bei der Umsetzung durch unser erfahrenes Entwicklerteam arbeiten wir agil und transparent, immer in
                enger Abstimmung mit dir als Partner.
              </p>
            </div>
            <div className="flex flex-col md:w-1/2 mt-5 md:mt-0 md:pl-4">
              <TimeLine
                className=""
                steps={[
                  {
                    time: 'Woche 1',
                    text: 'Vision & Strategie',
                  },
                  {
                    time: 'Woche 2',
                    text: 'Produktkonzept',
                  },
                  {
                    time: 'Woche 3 & 4',
                    text: 'Entwicklung & Testing',
                  },
                  {
                    time: '',
                    text: 'Dein MVP',
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full mt-8">
          <h3>Kosten</h3>

          <p>
            Die finalen Kosten hängen von Umfang und Typ des Projektes ab. Das MVP zur Validierung der Geschäftsidee
            sollte aber immer in einem kleinen Budgetrahmen umgesetzt werden können - Schließlich wollen wir schnelles
            Feedback vom Nutzer. Durch verschiedene Förderprogramme gibt es die Möglichkeit, die Kosten bis auf die Hälfte
            zu reduzieren. Sprich uns gerne an.
          </p>

          <div className="flex flex-row flex-wrap mt-8">
            <p className="leading-loose w-full md:w-1/2 ">
              Konzeption & Umsetzung
              <br />
              Mögliche Fördersumme für Digitalisierungsprojekte
            </p>
            <div className="flex flex-col w-full md:w-1/2 md:items-end leading-loose">
              <span className="font-semibold">30.000 bis 50.000 €</span>
              <span className="font-semibold italic">-25.000 €</span>

            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap w-full mt-10">
          <div className="flex flex-col w-full">
            <span className="font-bold">Beispielprojekt für MVP-Entwicklung</span>
            <CityLinkProjectBox />
          </div>
        </div>
      </div>
    </section>
    <AreYouInterested />
    <div className="flex flex-row flex-wrap">
      <div className="flex flex-col w-full md:items-center md:p-5">
        <h2 className="text-center">Wie wir arbeiten</h2>
        <div className="flex flex-row flex-wrap max-w-lg mt-12">
          <div className="flex flex-col w-full md:w-1/2 md:p-4 md:text-right">
            <h3 className="m-0">Unsere Lösungen sind nachhaltig & individuell.</h3>
          </div>
          <div className="flex flex-col w-full md:w-1/2 md:p-4">
            <p>Wir erschaffen passgenaue, auf dich und dein Problem abgestimmte Lösungen statt 08/15.  Dabei legen wir großen Wert auf Wartbarkeit & Erweiterbarkeit, entwickeln testgetrieben und beheben Fehler mit höchster Priorität.</p>
          </div>
        </div>

        <div className="flex flex-row flex-wrap max-w-lg mt-4">
          <div className="flex flex-col w-full md:w-1/2 md:p-4 md:text-right">
            <h3 className="m-0">Wir sind dein Partner.</h3>
          </div>
          <div className="flex flex-col w-full md:w-1/2 md:p-4">
            <p>Volle Transparenz und offene Kommunikation sind ein Must-Have für eine erfolgreiche Zusammenarbeit. Wir vertrauen auf schnellen und direkten Austausch zwischen Stakeholder, Produkt Owner und Entwickler.</p>
          </div>
        </div>

        <div className="flex flex-row flex-wrap max-w-lg mt-4">
          <div className="flex flex-col w-full md:w-1/2 md:p-4 md:text-right">
            <h3>Wir arbeiten agil & flexibel.</h3>
          </div>
          <div className="flex flex-col w-full md:w-1/2 md:p-4">
            <p>Wir entwickeln agil und in kurzen Iterationen - so sind Features innerhalb kürzester Zeit einsatzbereit. Neue Anforderungen und Feedback können wir jederzeit berücksichtigen,  priorisieren und einplanen.</p>
          </div>
        </div>

        <div className="flex flex-row flex-wrap max-w-lg mt-4">
          <div className="flex flex-col w-full md:w-1/2 md:p-4 md:text-right">
            <h3 className="m-0">Der Nutzer steht bei uns an erster Stelle.</h3>
          </div>
          <div className="flex flex-col w-full md:w-1/2 md:p-4">
            <p>Ein Produkt, das kein Problem löst, ist für uns wertlos. Wir entwickeln Lösungen, die exakt auf die Probleme unserer Kunden passen und den größten Mehrwert für den Endnutzer liefern.</p>
          </div>
        </div>

        <div className="flex flex-row flex-wrap max-w-lg mt-4">
          <div className="flex flex-col w-full md:w-1/2 md:p-4 md:text-right">
            <h3 className="m-0">Wir lieben was wir machen & Herausforderungen.</h3>
          </div>
          <div className="flex flex-col w-full md:w-1/2 md:p-4">
            <p>Digitale Produkte sind unsere Leidenschaft. Wir machen dein Produkt zu unserem und geben dafür gerne mehr als 100%.</p>
          </div>
        </div>
      </div>
    </div>

    <div className="flex flex-row flex-wrap justify-center">
      <div className="flex flex-col w-full md:items-center md:p-5 max-w-md">
        <h2 className="md:text-center">Technologien</h2>
        <p className="mt-2 md:mt-12 text-left">
          Wir haben den Anspruch für jedes Projekt die passenden Technologien auszuwählen. Dabei versuchen wir stets neueste Technologien zu nutzen.
        </p>
      </div>
      <ToolOverview />
    </div>
  </Layout>
);

export default ServicePage;
